<template>
  <div class="columns is-multiline">
    <modal-confirm-delete
      v-if="modalConfirm"
      :close="showModal"
      :action="deleteImage"
      :imageId="imageSelected"
    />
    <div class="column is-10 is-paddingless is-marginless has-text-left titleCard">
      {{productName}}
    </div>
    <div class="column is-2 is-paddingless is-marginless has-text-right">
      <button class="button is-danger" style="margin-right:10px;" @click="cancelEdit">
        <i class="material-icons click">close</i>
      </button>
      <button class="button is-success" @click="saveProduct">
        <i class="material-icons click">save</i>
      </button>
    </div>
    <div class="column is-12 separator"></div>
    <template v-if="!loading">
      <div class=" column is-12 productName field">
        <label class="label">Nombre</label>
        <div class="control">
          <input class="input" type="text" v-model="productInfo.productName" placeholder="Nombre del producto">
        </div>
      </div>
      <div class="column is-12">
        <label class="label">Descripción</label>
        <div class="control">
          <textarea class="input textarea" v-model="productInfo.description" placeholder="Descripción"></textarea>
        </div>
      </div>
      <div class="column is-4 field">
        <label class="label">Categoria</label>
        <div class="column is-4 field">
          <template v-for="category in newCategories">
            <span :key="category.id" class="tag">{{category.name}} </span>
          </template>
        </div>
      </div>
      <div class="column is-6 filed">
        <label class="label">Marca</label>
        <div class="select is-fullwidth">
          <select class="select" name="brand" v-model="productInfo.brand">
            <option :value="productInfo.brand" disabled>
              {{ brandName(productInfo.brand) }}
            </option>
            <template v-for="brand in brands">
              <option :value="brand.id" :key="brand.id"> {{ brand.name }} </option>
            </template>
          </select>
        </div>
      </div>
      <div class="column is-6 filed">
        <label class="label">Modelo</label>
        <div class="select is-fullwidth">
            <select class="select" name="model" v-model="productInfo.model">
                <option :value="productInfo.model" disabled>
                    {{ modelName(productInfo.model) }}
                </option>
                <template v-for="model in models">
                    <option :value="model.id" :key="model.id"> {{ model.name }} </option>
                </template>
            </select>
        </div>
      </div>
      <div class="column is-4 field">
        <label class="label">Precio</label>
        <div class="control">
          <input class="input" type="text" v-model="productInfo.price" placeholder="Precio del producto">
        </div>
      </div>
      <div class="column is-4 field">
        <label class="label">Stock</label>
        <div class="control">
          <input class="input" type="text" v-model="productInfo.stock" placeholder="Stock">
        </div>
      </div>
      <div class="column is-6 filed">
        <label class="label">Condición</label>
          <div class="select is-fullwidth">
            <select class="select" name="condition" v-model="productInfo.condition">
              <option :value="null" disabled> Selecciona una condición </option>
              <template v-for="condition in conditions">
                <option :value="condition.id" :key="condition.id"> {{condition.name}} </option>
              </template>
            </select>
        </div>
      </div>
      <div class="column is-6 filed">
        <label class="label">Lugar de deposito</label>
        <div class="select is-fullwidth">
          <select class="select" name="place_deposit" v-model="productInfo.place_deposit">
            <option :value="productInfo.place_deposit" disabled>
              {{ place_depositName(productInfo.place_deposit) }}
            </option>
            <template v-for="place_deposit in place_deposit">
              <option :value="place_deposit.id" :key="place_deposit.id"> {{ place_deposit.name }} </option>
            </template>
          </select>
        </div>
      </div>
      <div class="column is-6">
        <div class="field">
          <label class="label">¿Cuenta con caja original?</label>
          <div class="select is-fullwidth" :class="{ 'is-danger': vverrors.first('productInfo.box') }">
            <select
              name="productInfo.box"
              v-model="productInfo.box"
              v-validate="'required|excluded:-1'"
            >
              <option :value="null" disabled>Seleccione una opción</option>
              <option :value="true"> Si</option>
              <option :value="false"> No</option>
            </select>
          </div>
        </div>
      </div>
      <div class="column is-12">
        <div class="field">
          <label class="label">¿Cuenta con accesorios originales(cargador y cable)?</label>
          <div class="select is-fullwidth" :class="{ 'is-danger': vverrors.first('productInfo.accessories') }">
            <select
              name="productInfo.accessories"
              v-model="productInfo.accessories"
              v-validate="'required|excluded:-1'"
            >
              <option :value="null" disabled>Seleccione una opción</option>
              <option :value="true"> Si</option>
              <option :value="false"> No</option>
            </select>
          </div>
        </div>
      </div>
      <template v-for="(characteristic, index) of productInfo.characteristic">
        <div class="column is-4" :key="characteristic.id">
          <div class="field">
            <label class="label">{{characteristic.characteristic}}</label>
            <div class="control">
              <input
                  class="input"
                  type="text"
                  :value="characteristic.value"
                  :name="characteristic.characteristic"
                  @input="setValueCharacteristic($event, index)"
                  :class="{ 'is-danger': vverrors.first(characteristic.characteristic) }"
                  v-validate="'required'"
              >
            </div>
          </div>
        </div>
      </template>
      <div class="column is-12">
        <strong>Fotos del producto: </strong>
      </div>
      <div class="column is-6">
        <label class="label">Subir Imagen</label>
        <div class="control">
          <input
            class="input"
            type="file"
            name="image"
            @change="uploadFile"
            data-vv-as="Imagen"
            v-validate="'required|ext:jpeg,jpg,png'"
            :class="{'is-danger': vverrors.has('image')}"
            accept=".jpeg,.jpg,.png"
            ref="imageInput"
          >
          <p class="help is-danger"> {{vverrors.first('image')}} </p>
        </div>
      </div>
      <div class="column is-3">
        <label class="label">Tipo</label>
        <div class="select">
          <select class="select" v-model="main">
            <option :value="false" selected> Foto </option>
            <option :value="true"> Portada </option>
          </select>
        </div>
      </div>
      <div class="column is-3">
        <label class="label">-</label>
        <div class="control">
          <button type="button" @click="updloadImage" class="button is-info">Subir</button>
        </div>
      </div>
      <template v-for="(image, index) of productInfo.images">
        <div class="column is-3" :key="index">
          <p class="has-text-centered">
            {{image.main ? 'Portada' : 'Foto'}}
            <i class="material-icons is-pulled-right click" @click="showModal(image.id)">delete</i>
          </p>
          <div @click="changeCover(image.id)" :style="`background-image: url('${image.url}')`" class="productImage" :class="image.main ? 'borderFocus': ''"></div>
        </div>
      </template>
      <div class="column is-3" :class="{ 'is-loading': loadingImage }" v-if="loadingImage">
        <loading-component />
      </div>
    </template>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  props: {
    productId: {
      type: Number
    },
    detail: {
      type: Object,
      required: true
    },
    updateProduct: {
      type: Function,
      default: () => {}
    },
    cancelEdit: {
      type: Function,
      default: () => {}
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  components: {
    ModalConfirmDelete: () => import('@/components/Products/ModalConfirmDelete'),
    LoadingComponent: () => import('@/components/ui/Loading')
  },
  data () {
    return {
      modalConfirm: false,
      productInfo: this.detail,
      productName: this.detail.name,
      categories: [],
      conditions: [
        { id: 1, name: 'Nuevo' },
        { id: 2, name: 'Usado' }
      ],
      place_deposit: [
        { id: 1, name: 'DHL' },
        { id: 2, name: 'WeWork Montes Úrales' },
        { id: 3, name: 'German Centre  Av. Santa Fe 170' }
      ],
      brands: [
        { id: 1, name: 'Samsung' },
        { id: 2, name: 'iPhone' }
      ],
      newCategories: [],
      deletedCategories: [],
      imageSelected: null,
      newImage: null,
      main: false,
      loadingImage: false,
      'models': [
        {
          'id': 1,
          'name': 'X',
          'brand_id': 2,
          'created_at': '2023-10-06T20:13:48.427Z',
          'updated_at': '2023-10-06T20:13:48.427Z',
          'active': true
        },
        {
          'id': 2,
          'name': 'XR',
          'brand_id': 2,
          'created_at': '2023-10-06T20:13:48.427Z',
          'updated_at': '2023-10-06T20:13:48.427Z',
          'active': true
        },
        {
          'id': 3,
          'name': 'XS',
          'brand_id': 2,
          'created_at': '2023-10-06T20:13:48.427Z',
          'updated_at': '2023-10-06T20:13:48.427Z',
          'active': true
        },
        {
          'id': 4,
          'name': 'XS Max',
          'brand_id': 2,
          'created_at': '2023-10-06T20:13:48.427Z',
          'updated_at': '2023-10-06T20:13:48.427Z',
          'active': true
        },
        {
          'id': 5,
          'name': '11',
          'brand_id': 2,
          'created_at': '2023-10-06T20:13:48.427Z',
          'updated_at': '2023-10-06T20:13:48.427Z',
          'active': true
        },
        {
          'id': 6,
          'name': '11 Pro',
          'brand_id': 2,
          'created_at': '2023-10-06T20:13:48.427Z',
          'updated_at': '2023-10-06T20:13:48.427Z',
          'active': true
        },
        {
          'id': 7,
          'name': '11 Pro Max',
          'brand_id': 2,
          'created_at': '2023-10-06T20:13:48.427Z',
          'updated_at': '2023-10-06T20:13:48.427Z',
          'active': true
        },
        {
          'id': 8,
          'name': 'SE (2nd generation)',
          'brand_id': 2,
          'created_at': '2023-10-06T20:13:48.427Z',
          'updated_at': '2023-10-06T20:13:48.427Z',
          'active': true
        },
        {
          'id': 9,
          'name': '12 Mini',
          'brand_id': 2,
          'created_at': '2023-10-06T20:13:48.427Z',
          'updated_at': '2023-10-06T20:13:48.427Z',
          'active': true
        },
        {
          'id': 10,
          'name': '12',
          'brand_id': 2,
          'created_at': '2023-10-06T20:13:48.427Z',
          'updated_at': '2023-10-06T20:13:48.427Z',
          'active': true
        },
        {
          'id': 11,
          'name': '12 Pro',
          'brand_id': 2,
          'created_at': '2023-10-06T20:13:48.427Z',
          'updated_at': '2023-10-06T20:13:48.427Z',
          'active': true
        },
        {
          'id': 12,
          'name': '12 Pro Max',
          'brand_id': 2,
          'created_at': '2023-10-06T20:13:48.427Z',
          'updated_at': '2023-10-06T20:13:48.427Z',
          'active': true
        },
        {
          'id': 13,
          'name': '13 Mini',
          'brand_id': 2,
          'created_at': '2023-10-06T20:13:48.427Z',
          'updated_at': '2023-10-06T20:13:48.427Z',
          'active': true
        },
        {
          'id': 14,
          'name': '13',
          'brand_id': 2,
          'created_at': '2023-10-06T20:13:48.427Z',
          'updated_at': '2023-10-06T20:13:48.427Z',
          'active': true
        },
        {
          'id': 15,
          'name': '13 Pro',
          'brand_id': 2,
          'created_at': '2023-10-06T20:13:48.427Z',
          'updated_at': '2023-10-06T20:13:48.427Z',
          'active': true
        },
        {
          'id': 16,
          'name': '13 Pro Max',
          'brand_id': 2,
          'created_at': '2023-10-06T20:13:48.427Z',
          'updated_at': '2023-10-06T20:13:48.427Z',
          'active': true
        },
        {
          'id': 17,
          'name': '14 Mini',
          'brand_id': 2,
          'created_at': '2023-10-06T20:13:48.427Z',
          'updated_at': '2023-10-06T20:13:48.427Z',
          'active': true
        },
        {
          'id': 18,
          'name': '14',
          'brand_id': 2,
          'created_at': '2023-10-06T20:13:48.427Z',
          'updated_at': '2023-10-06T20:13:48.427Z',
          'active': true
        },
        {
          'id': 19,
          'name': '14 Pro',
          'brand_id': 2,
          'created_at': '2023-10-06T20:13:48.427Z',
          'updated_at': '2023-10-06T20:13:48.427Z',
          'active': true
        },
        {
          'id': 20,
          'name': '14 Pro Max',
          'brand_id': 2,
          'created_at': '2023-10-06T20:13:48.427Z',
          'updated_at': '2023-10-06T20:13:48.427Z',
          'active': true
        },
        {
          'id': 30,
          'name': '15',
          'brand_id': 2,
          'created_at': '2023-10-06T20:13:48.427Z',
          'updated_at': '2023-10-06T20:13:48.427Z',
          'active': true
        },
        {
          'id': 31,
          'name': '15 mini',
          'brand_id': 2,
          'created_at': '2023-10-06T20:13:48.427Z',
          'updated_at': '2023-10-06T20:13:48.427Z',
          'active': true
        },
        {
          'id': 32,
          'name': '15 Pro',
          'brand_id': 2,
          'created_at': '2023-10-06T20:13:48.427Z',
          'updated_at': '2023-10-06T20:13:48.427Z',
          'active': true
        },
        {
          'id': 33,
          'name': '15 Pro Max',
          'brand_id': 2,
          'created_at': '2023-10-06T20:13:48.427Z',
          'updated_at': '2023-10-06T20:13:48.427Z',
          'active': true
        },
        {
          'id': 21,
          'name': 'Galaxy S22 Ultra',
          'brand_id': 1,
          'created_at': '2023-10-06T20:16:25.200Z',
          'updated_at': '2023-10-06T20:16:25.200Z',
          'active': true
        },
        {
          'id': 22,
          'name': 'Galaxy S22+',
          'brand_id': 1,
          'created_at': '2023-10-06T20:16:25.200Z',
          'updated_at': '2023-10-06T20:16:25.200Z',
          'active': true
        },
        {
          'id': 23,
          'name': 'Galaxy S22',
          'brand_id': 1,
          'created_at': '2023-10-06T20:16:25.200Z',
          'updated_at': '2023-10-06T20:16:25.200Z',
          'active': true
        },
        {
          'id': 24,
          'name': 'Galaxy Z Fold3',
          'brand_id': 1,
          'created_at': '2023-10-06T20:16:25.200Z',
          'updated_at': '2023-10-06T20:16:25.200Z',
          'active': true
        },
        {
          'id': 25,
          'name': 'Galaxy Z Flip3',
          'brand_id': 1,
          'created_at': '2023-10-06T20:16:25.200Z',
          'updated_at': '2023-10-06T20:16:25.200Z',
          'active': true
        },
        {
          'id': 26,
          'name': 'Galaxy A52 5G',
          'brand_id': 1,
          'created_at': '2023-10-06T20:16:25.200Z',
          'updated_at': '2023-10-06T20:16:25.200Z',
          'active': true
        },
        {
          'id': 27,
          'name': 'Galaxy A32 5G',
          'brand_id': 1,
          'created_at': '2023-10-06T20:16:25.200Z',
          'updated_at': '2023-10-06T20:16:25.200Z',
          'active': true
        },
        {
          'id': 28,
          'name': 'Galaxy A12',
          'brand_id': 1,
          'created_at': '2023-10-06T20:16:25.200Z',
          'updated_at': '2023-10-06T20:16:25.200Z',
          'active': true
        },
        {
          'id': 29,
          'name': 'Galaxy A02s',
          'brand_id': 1,
          'created_at': '2023-10-06T20:16:25.200Z',
          'updated_at': '2023-10-06T20:16:25.200Z',
          'active': true
        }
      ]
    }
  },
  mounted: {
    filteredModels () {
      return this.models.filter(modelObj => modelObj.brand_id === this.brand)
    }
  },
  methods: {
    ...mapActions(['getCatalogCategories', 'deleteImageProduct', 'uploadImageProduct', 'changeCoverProduct']),
    changeCover (id) {
      const change = this.productInfo.images.map(image => {
        return { ...image, main: image.id === id || false }
      })
      this.productInfo.images = change
    },
    place_depositName (id) {
      const numericId = typeof id === 'string' ? parseInt(id, 10) : id
      const placeDepositObj = this.place_deposit.find(placeDeposit => placeDeposit.id === numericId)
      return placeDepositObj ? placeDepositObj.name : String(numericId)
    },
    brandName (id) {
      const brandObj = this.brands.find(brand => brand.id === id)
      return brandObj ? brandObj.name : String(id)
    },
    modelName (id) {
      const modelObj = this.models.find(model => model.id === id)
      return modelObj ? modelObj.name : String(id)
    },
    formatMoney (amount) {
      return parseInt(amount).toLocaleString('en', { style: 'currency', currency: 'USD' })
    },
    showModal (imageId) {
      this.imageSelected = imageId
      this.modalConfirm = !this.modalConfirm
    },
    uploadFile (event) {
      const [ file ] = event.target.files
      this.newImage = { key: event.target.name, file }
    },
    setValueCharacteristic (event, index) {
      this.productInfo.characteristic[index].value = event.target.value
    },
    async updloadImage () {
      if (await this.$validator.validateAll()) {
        this.loadingImage = true
        const file = this.newImage
        const main = this.main
        this.uploadImageProduct({ productId: parseInt(this.productId), file, main })
          .then(response => {
            if (response.success) {
              this.$refs.imageInput.value = ''
              setTimeout(() => {
                this.loadingImage = false
                if (response.image.main) {
                  this.productInfo.images = this.productInfo.images.map(image => {
                    return { ...image, main: false }
                  })
                }
                this.productInfo.images.push({ ...response.image })
              }, 3000)
            }
          })
      }
    },
    saveProduct () {
      const product = {
        name: this.productInfo.productName,
        description: this.productInfo.description,
        brand: this.productInfo.brand,
        model: this.productInfo.model,
        price: this.productInfo.price,
        stock: this.productInfo.stock,
        condition: this.productInfo.condition,
        place_deposit: String(this.productInfo.place_deposit),
        box: this.productInfo.box,
        accessories: this.productInfo.accessories,
        categories: this.newCategories,
        deletedCategories: this.deletedCategories,
        characteristic: this.productInfo.characteristic
      }
      console.log('product', product)
      this.updateProduct(product)
      this.changeCoverProduct({ productId: parseInt(this.productId), data: this.productInfo.images })
    },
    deleteCategories (category) {
      this.newCategories = this.newCategories.filter(cat => cat.id !== category.id)
      this.deletedCategories.push(category)
      this.categories.push(category)
    },
    addCategories ({ target }) {
      if (target.value) {
        const cat = this.categories.find(cat => parseInt(cat.id) === parseInt(target.value))
        if (cat) {
          this.newCategories.push(cat)
          this.categories = this.categories.filter(cat => parseInt(cat.id) !== parseInt(target.value))
          this.deletedCategories = this.deletedCategories.filter(cat => parseInt(cat.id) !== parseInt(target.value))
        }
      }
    },
    deleteImage (imageId) {
      const image = {
        id: imageId
      }
      this.deleteImageProduct({ productId: parseInt(this.productId), image })
        .then(success => {
          if (success) {
            this.productInfo.images = this.productInfo.images.filter(image => parseInt(image.id) !== parseInt(imageId))
            this.showModal()
          }
        })
    }
  },
  beforeMount () {
    this.newCategories = this.detail.categories
    this.getCatalogCategories()
      .then(categories => {
        this.categories = categories.reduce((accum, category) => {
          const isSelected = this.detail.categories.find(cat => parseInt(cat.id) === parseInt(category.id))
          if (!isSelected) {
            accum.push(category)
          }
          return accum
        }, [])
      })
  }
}
</script>

<style scoped>
.productName {
    font-size: 16px;
  }
 .productImage {
    width: 100%;
    height: 150px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer
  }
  .click {
    cursor: pointer;
  }
  .material-icons.deleteCat {
    font-size: 16px;
    margin-left: 5px;
  }
  .tag {
    margin-right: 10px;
  }
  .borderFocus {
    border: 4px solid #209cee;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
  }
</style>
